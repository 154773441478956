import { /*CommunityForm,*/ ProfessionalForm, TeamsForm, EnterpriseForm } from "./PricingForms";
import { inputs } from "./Consts";

const table = (id, form) => {

    let fields = {};
    form.fields.forEach(
        i => {
            if (i.dropDown)
            {
                fields[i.field] = inputs[i.field](i.text, i.dropDown.split(",").map(j => j.trim()));
            }
            
            fields[i.field] = inputs[i.field](i.text);
        }
    );

    switch (id)
    {
        //case "community-free": return CommunityForm(...);
        case "professional-75":
            return ProfessionalForm(form.header, form.paragraph, form.btnText, fields, form.footer);
        case "teams-99":
            return TeamsForm(form.header, form.paragraph, form.btnText, fields, form.footer);
        case "enterprise-custom":
            return EnterpriseForm(form.header, form.paragraph, form.btnText, fields, form.footer);
    }

    return null;
};

export default table;